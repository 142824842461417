// Базовые компоненты
import Vue from 'vue'
import Router from 'vue-router'

// Системные компоненты
const main = () => import("@/components/main-form.vue");
const general = () => import("@/components/general-form.vue");
const documentation = () => import("@/components/documentation-form.vue");
const contacts = () => import("@/components/contacts-form.vue");

// general
const basic = () => import("@/components/basic-form.vue");
const possibility = () => import("@/components/possibility-form.vue");
const begin = () => import("@/components/begin-form.vue");
const changelog = () => import("@/components/changelog-form.vue");
const plans = () => import("@/components/plans-form.vue");

// documentation
const registration = () => import("@/components/registration-form.vue");
const account = () => import("@/components/account-form.vue");
const conglomerate = () => import("@/components/conglomerate-form.vue");
const tariff = () => import("@/components/tariff-form.vue");
const frontsystem = () => import("@/components/frontsystem-form.vue");
const connection_fs = () => import("@/components/connection_fs.vue");
const connection_fs_module = () => import("@/components/connection_fs_module.vue");
const connection_fs_clear = () => import("@/components/connection_fs_clear.vue");
const backsystem = () => import("@/components/backsystem-form.vue");
const connection_ws = () => import("@/components/connection_ws.vue");
const connection_proxyws = () => import("@/components/connection_proxyws.vue");
const logs = () => import("@/components/logs-form.vue");

Vue.use(Router);


export default new Router({
  routes: [
    {
      path: '/',
      name: 'main',
      component: main,
    }, {
      path: '/general',
      name: 'general',
      component: general,
      children:[
        {
          path: '/general/basic',
          name: 'basic',
          component: basic,
        }, {
          path: '/general/possibility',
          name: 'possibility',
          component: possibility,
        }, {
          path: '/general/begin',
          name: 'begin',
          component: begin,
        }, {
          path: '/general/changelog',
          name: 'changelog',
          component: changelog,
        }, {
          path: '/general/plans',
          name: 'plans',
          component: plans,
        },
      ]
        }, {
      path: '/documentation',
      name: 'documentation',
      component: documentation,
      children:[
        {
          path: '/documentation/registration',
          name: 'registration',
          component: registration,
        },{
          path: '/documentation/account',
          name: 'account',
          component: account,
        },{
          path: '/documentation/tariff',
          name: 'tariff',
          component: tariff,
        },{
          path: '/documentation/frontsystem',
          name: 'frontsystem',
          component: frontsystem,
        },{
          path: '/documentation/connection_fs',
          name: 'connection_fs',
          component: connection_fs,
          children:[
            {
              path: '/documentation/connection_fs/connection_fs_module',
              name: 'connection_fs_module',
              component: connection_fs_module,
            }, {
              path: '/documentation/connection_fs/connection_fs_clear',
              name: 'connection_fs_clear',
              component: connection_fs_clear,
            },
          ]
        },{
          path: '/documentation/backsystem',
          name: 'backsystem',
          component: backsystem,
        },{
          path: '/documentation/connection_ws',
          name: 'connection_ws',
          component: connection_ws,
        },{
          path: '/documentation/connection_proxyws',
          name: 'connection_proxyws',
          component: connection_proxyws,
        },{
          path: '/documentation/logs',
          name: 'logs',
          component: logs,
        },
        {
          path: '/documentation/conglomerate',
          name: 'conglomerate',
          component: conglomerate,
        },
      ]
        }, {
      path: '/contacts',
      name: 'contacts',
      component: contacts,
        },
      ]
})

