import Vue from 'vue';
import ElementUI from 'element-ui';

import locale from 'element-ui/lib/locale/lang/ru-RU';

import 'element-ui/lib/theme-chalk/index.css';

const App = () => import("@/App.vue");

import router from './router';

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });

new Vue({
  el: '#app',
  router: router,
  render: h => h(App)
});